import React from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import {
  Row,
  Col,
  Button,
  Accordion,
  Card,
  useAccordionToggle,
} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"

export const ContributePageTemplate = ({ title, section1, sectionRoles }) => {
  const { role1, role2, role3, role4, role5 } = sectionRoles
  return (
    <Layout>
      <SEO title="Contribute" />
      <Row className="mx-0" style={{ marginTop: "-73px" }}>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center cont-landing-image">
            <div className="overlay"></div>
            <div style={{ height: "50vh" }}>
              <h1 className="sectionTitle">{title}</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex flex-column justify-content-center align-items-center cont-section2">
        <Col xs={10} lg={8}>
          <ReactMarkdown source={section1} escapeHtml={false} />
        </Col>
      </Row>

      <Row className="cont-section-roles">
        <div className="overlay-top"></div>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center bg-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle">Roles</h1>
            </div>
          </div>
        </Col>
        <div className="overlay-bottom"></div>
      </Row>
      <Row className="cont-section-roles-after d-flex flex-row justify-content-center align-items-center pt-5">
        <div className="overlay-top"></div>
        <Col xs={12} md={10} xl={8} className="mb-5">
          <Accordion className="cont-accordion">
            {/* LIGHTSCHOOL */}
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <Row className="d-flex flex-row justify-content-center align-items-center pt-5">
                <Col xs={6} lg={{ span: 4, offset: 1 }}>
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: role1.image,
                      className: "image",
                      style: { maxWidth: "200px", margin: "auto" },
                    }}
                  />
                </Col>
                <Col xs={6} lg={{ span: 6, offset: 1 }}>
                  <h2>
                    {role1.title}
                    <svg
                      class="bi bi-caret-down-fill"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      style={{ marginLeft: "8px" }}
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                    </svg>
                  </h2>
                  <p>{role1.subTitle}</p>
                </Col>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <ReactMarkdown
                      source={role1.content}
                      escapeHtml={false}
                      linkTarget="_blank"
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Row>
            </Accordion.Toggle>
          </Accordion>

          <Accordion className="cont-accordion">
            {/* TEACHER */}
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <Row
                className="d-flex flex-row justify-content-center align-items-center"
                style={{ marginTop: "10%" }}
              >
                <Col xs={6} lg={{ span: 6 }} style={{ textAlign: "right" }}>
                  <h2 style={{ textAlign: "right" }}>
                    {role2.title}
                    <svg
                      class="bi bi-caret-down-fill"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      style={{ marginLeft: "8px" }}
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                    </svg>
                  </h2>
                  <p style={{ textAlign: "right" }}>{role2.subTitle}</p>
                </Col>
                <Col xs={6} lg={4}>
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: role2.image,
                      className: "image",
                      style: { maxWidth: "200px", margin: "auto" },
                    }}
                  />
                </Col>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <ReactMarkdown
                      source={role2.content}
                      escapeHtml={false}
                      linkTarget="_blank"
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Row>
            </Accordion.Toggle>
          </Accordion>
          <Accordion className="cont-accordion">
            {/* CONNECTOR */}
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <Row style={{ marginTop: "10%" }}>
                <Col xs={6} lg={{ span: 4, offset: 1 }}>
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: role3.image,
                      className: "image",
                      style: { maxWidth: "200px", margin: "auto" },
                    }}
                  />
                </Col>
                <Col xs={6} lg={{ span: 6, offset: 1 }}>
                  <h2>
                    {role3.title}
                    <svg
                      class="bi bi-caret-down-fill"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      style={{ marginLeft: "8px" }}
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                    </svg>
                  </h2>
                  <p>{role3.subTitle}</p>
                </Col>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <ReactMarkdown
                      source={role3.content}
                      escapeHtml={false}
                      linkTarget="_blank"
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Row>
            </Accordion.Toggle>
          </Accordion>

          <Accordion className="cont-accordion">
            {/* STUDENT */}
            <Accordion.Toggle as={Card.Header} eventKey="3">
              <Row style={{ marginTop: "10%" }}>
                <Col xs={6} lg={{ span: 6 }} style={{ textAlign: "right" }}>
                  <h2>
                    {role4.title}
                    <svg
                      class="bi bi-caret-down-fill"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      style={{ marginLeft: "8px" }}
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                    </svg>
                  </h2>
                  <p>{role4.subTitle}</p>
                </Col>
                <Col xs={6} lg={4}>
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: role4.image,
                      className: "image",
                      style: { maxWidth: "200px", margin: "auto" },
                    }}
                  />
                </Col>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <ReactMarkdown
                      source={role4.content}
                      escapeHtml={false}
                      linkTarget="_blank"
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Row>
            </Accordion.Toggle>
          </Accordion>
          <Accordion className="cont-accordion">
            {/* ORG and AUTH */}
            <Accordion.Toggle as={Card.Header} eventKey="4">
              <Row className="d-flex flex-row justify-content-center align-items-center pt-5">
                <Col xs={6} lg={4}>
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: role5.image,
                      className: "image",
                      style: { maxWidth: "200px", margin: "auto" },
                    }}
                  />
                </Col>
                <Col xs={6} lg={{ span: 6, offset: 1 }}>
                  <h2>
                    {role5.title}
                    <svg
                      class="bi bi-caret-down-fill"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      style={{ marginLeft: "8px" }}
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                    </svg>
                  </h2>
                  <p>{role5.subTitle}</p>
                </Col>
              </Row>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <ReactMarkdown
                    source={role5.content}
                    escapeHtml={false}
                    linkTarget="_blank"
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Accordion.Toggle>
          </Accordion>
        </Col>
      </Row>
      <Row className="cont-section-learnMore d-flex flex-column justify-content-center align-items-center">
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center bg-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle">Learn more</h1>
              <Row className="d-flex flex-row">
                <Col xs={6}>
                  <Link to="/academy">
                    <Button>ACADEMY</Button>
                  </Link>
                </Col>
                <Col xs={6}>
                  <a href="http://members.lightschools.org">
                    <Button>MEMBERS</Button>
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <div className="overlay-bottom"></div>
      </Row>
    </Layout>
  )
}

const ContributePage = props => {
  const { frontmatter } = props.data.ContributePageTemplate
  console.log(frontmatter)
  return (
    <ContributePageTemplate
      title={frontmatter.title}
      section1={frontmatter.section1}
      sectionRoles={frontmatter.sectionRoles}
    />
  )
}

export default ContributePage

export const pageQuery = graphql`
  query($id: String!) {
    ContributePageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        section1
        sectionRoles {
          role1 {
            title
            subTitle
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            content
          }
          role2 {
            title
            subTitle
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            content
          }
          role3 {
            title
            subTitle
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            content
          }
          role4 {
            title
            subTitle
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            content
          }
          role5 {
            title
            subTitle
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            content
          }
        }
      }
    }
  }
`
